.nav {
  display: flex;
  justify-content: space-between;
  margin: auto;
  list-style: none;
  max-height: var(--nav-height);
}

.nav a {
  color: var(--text-color);
  text-decoration: none;
}

.nav__wrapper {
  display: flex;
  align-items: center;
}

.nav__wrapper li {
  display: flex;
  align-items: center;
  list-style: none;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
}

.nav__wrapper ul,
.nav__wrapper li,
.nav__wrapper a {
  height: 100%;
}

.nav__wrapper li {
  padding-top: 2.3rem;
  margin-right: 2rem;
}

.nav__wrapper li:hover {
  border-bottom: 4px solid var(--color-tertiary);
}

.nav__wrapper--mobile {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50vw;
  background-color: #fff;
  z-index: 25;
  border: thin solid #eee;
  transition: all 0.7s ease-out;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-end;
}

.nav__shadow {
  box-shadow: 0px 1px 25px 1px rgba(255, 255, 255, 0.796);
}

.nav__wrapper--mobile-show {
  transform: translateX(0);
}

.nav__wrapper--mobile-hide {
  transform: translateX(0);
}

.nav__wrapper--mobile li {
  list-style: none;
  padding: 1.745rem;
  display: flex;
  border-bottom: thin solid #aaa;
  font-weight: 500;
  text-transform: uppercase;
}

.logo {
  margin: 1rem 0;
}

.icon_container {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 35px 0;
}

.icon_container img {
  height: 80px;
}

.sticky {
  justify-content: space-between;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.92);
  z-index: 15;
  width: 100%;
  border-bottom: thin solid #ddd;
}

.btn__mobile_nav {
  display: flex;
  align-items: center;
}

.btn__mobile_nav svg {
  font-size: 3.5rem;
  border: thin solid #aaa;
  border-radius: 10px;
  cursor: pointer;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

@media screen and (max-width: 639px) {
  .nav {
    margin: 0 5px;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .nav {
    margin: 0 25px;
  }
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
  .nav {
    max-width: 95%;
  }
}
@media screen and (min-width: 1440px) {
  .nav {
    max-width: 60%;
  }
  .nav li:hover {
    border-bottom: 4px var(--color-septenary) solid;
  }
}
