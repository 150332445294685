@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");

:root {
  --nav-height: 96px;
  --text-color: #444;
  --color-primary: #4a556d;
  --color-secondary: #626e86;
  --color-tertiary: #de684e;
  --color-quaternary: #ffcf69;
  --color-quinary: #4dbc89;
  --color-senary: rgba(77, 188, 137, 0.89);
  --color-septenary: #f77f64;

  --gradient-primary: linear-gradient(
    to right,
    var(--color-tertiary),
    var(--color-quaternary)
  );
  --gradient-primary-reverse: linear-gradient(
    to left,
    var(--color-tertiary),
    var(--color-quaternary)
  );
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  color: var(--text-color);
  background-color: rgb(250, 250, 250);
}

.highlight {
  position: relative;
  z-index: 100;
}

.highlight::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.7;
  transform: scale(1.07, 1.05) skewX(-15deg);
  background-image: var(--gradient-primary);
}
