.column {
  width: 100%;
}

.column > h1 {
  font-size: 2rem;
  margin: 1rem 0;
}

.column > p,
.column b {
  font-size: 1.1rem;
  line-height: 1.7;
}

.column img {
  height: 450px;
}
/* Hide image breakpoint */
@media screen and (max-width: 960px) {
  .column__image {
    display: none;
  }
}

@media screen and (max-width: 639px) {
  .column {
    padding: 2rem;
  }

  .column > h1 {
    font-size: 2.1rem;
  }

  .column > h2 {
    font-size: 1.8rem;
  }

  .column > p {
    font-size: 0.9rem;
    padding-top: 15px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1007px) {
  .column img {
    height: 300px;
  }
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
  .column img {
    height: 400px;
  }
}
@media screen and (min-width: 1440px) {
}
