.form {
  display: flex;
  flex-direction: column;
}

.form button {
  margin-top: 1rem;
}

@media screen and (min-width: 600px) {
  .form button {
    margin-left: auto;
  }
}

@media screen and (max-width: 639px) {
  .container h1 {
    font-size: 1.4rem;
  }
  .container p {
    font-size: 0.765rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .container h1 {
    font-size: 2rem;
  }
  .container p {
    font-size: 1rem;
  }
}
