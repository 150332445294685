.footer {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ddd;
  background-color: #37383d;
  /* height: 40vh; */
}

.links ul {
  margin: 1rem 0;
}

.links ul li {
  display: inline-block;
  color: #eee;
  list-style: none;
  margin: 0 1.5rem;
  font-size: 1rem;
}

.links a {
  text-decoration: none;
  color: inherit;
}

.footer img {
  height: 70px;
}

.copyright {
  margin: 1rem 0;
  color: #aaa;
  text-align: center;
}

@media screen and (max-width: 639px) {
  .footer {
    padding: 0.5rem;
  }
  .links ul li {
    margin: 0 0.6rem;
    font-size: 0.5rem;
  }
  .copyright {
    margin: 1rem 0;
    color: #aaa;
    text-align: center;
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .footer {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
  .links ul li {
    margin: 0 1.2rem;
    font-size: 0.8rem;
  }
}
