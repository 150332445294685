.logo img {
  height: 70px;
}

@media screen and (max-width: 639px) {
  .logo img {
    height: 45px;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
}
@media screen and (min-width: 1440px) {
}
