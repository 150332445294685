.slider__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 45%;
  z-index: 100;

  border: none;
  background-color: #ccc;
  font-family: inherit;
  color: #333;
  border-radius: 20%;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

.slider__btn:hover {
  background-color: #ddd;
}

.slider__btn--left {
  left: 4%;
}

.slider__btn--right {
  right: 4%;
}

.slider__btn--icon {
  font-size: 3rem;
}

@media screen and (max-width: 639px) {
  .slider__btn {
    top: 85%;
    height: 2.7rem;
    width: 2.7rem;
  }
  .slider__btn svg {
    font-size: 1.9rem;
  }

  .slider__btn--left {
    left: 10%;
  }
  .slider__btn--right {
    right: 10%;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .slider__btn {
    top: 81%;
  }
  .slider__btn--left {
    left: 15%;
  }
  .slider__btn--right {
    right: 15%;
  }
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
}
@media screen and (min-width: 1440px) {
}
