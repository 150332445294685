.container__button {
  display: flex;
  gap: 0.75rem;
}

@media screen and (max-width: 639px) {
  .container__button {
    flex-direction: column;
    gap: 0.1rem;
  }

  .container__button button {
    padding: 20px;
    font-size: 1.3rem;
  }
}
