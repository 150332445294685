.dots {
  position: absolute;
  top: 83%;
  left: 50%;
  /* bottom: 50%; */
  transform: translateX(-50%);
}

.dot {
  border: none;
  background-color: #b9b9b9;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;
}

.dot:last-child {
  margin: 0;
}

.dot__active {
  background-color: #888;
  opacity: 1;
}

@media screen and (max-width: 639px) {
  .dots {
    top: 86%;
  }
  .dot {
    width: 0.8rem;
    height: 0.8rem;
  }
}
