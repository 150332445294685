.redirect {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ddd;
}

.redirect h2 {
  font-weight: 100;
  font-family: Arial, Helvetica, sans-serif;
}
