.section--flex,
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("");
}

.section {
  height: 70vh;
  border-top: 1px solid #ddd;
  transition: all 0.3s ease-out;
}

.container {
  margin: auto;
  width: 100%;
  height: 100%;
}

.show {
  opacity: 1;
  transform: translateY(0rem);
}

.hide {
  opacity: 0;
  transform: translateY(3rem);
}

@media screen and (max-width: 639px) {
  .container {
    max-width: 98%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1007px) {
  .container {
    max-width: 80%;
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1008px) and (max-width: 1439px) {
  .container {
    max-width: 80%;
    gap: 4rem;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 60%;
    gap: 2.5rem;
  }
}
