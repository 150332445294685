.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slider {
  position: relative;
  overflow: hidden;
  height: 100%;
}

@media screen and (max-width: 639px) {
  .slider {
    width: 100%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1007px) {
  .slider {
    width: 100%;
  }
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
  .slider {
    width: 80%;
  }
}
@media screen and (min-width: 1440px) {
  .slider {
    width: 80%;
  }
}
