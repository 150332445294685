.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.7s ease-out;
}

.service {
  width: 90%;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.2);
  padding: 2.5rem;
}

.service li svg {
  color: var(--color-quinary);
}

.service__header {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.service__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem; /* text-align: justify; */
  width: 4rem;
  border-radius: 50%;
  background: var(--gradient-primary-reverse);
}

.service__icon > svg {
  height: 2rem;
  width: 2rem;
  fill: #fff;
}

.service__header h2 {
  font-size: 2rem;
}

.service p {
  font-size: 1.33rem;
  line-height: 2;
}

.service .bold {
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
}

.service ul {
  columns: 2 5rem;
  /* column-gap: 3rem; */
  list-style: none;
  padding: 0 1rem;
}

.service ul li {
  font-size: 1.4rem;
}

.service li {
  margin: 0;
  padding: 0;
}
.list__icon {
  font-size: 1.8rem;
  color: var(--color-quinary);
}

@media screen and (max-width: 375px) {
  .service {
    gap: 0rem;
    padding: 1.5rem !important;
  }
  .service h2 {
    font-size: 1.1rem !important;
  }

  .service ul li {
    font-size: 0.9rem !important;
    line-height: 1.6rem !important;
  }

  .service p {
    font-size: 0.9rem !important;
  }
}

@media screen and (min-height: 825px) {
  .service p {
    line-height: 1.9 !important;
  }
}

@media screen and (min-height: 895px) {
  .service p {
    line-height: 2.3 !important;
  }
}

@media screen and (max-width: 639px) {
  .service {
    padding: 1.2rem;
    width: 95%;
    gap: 0rem;
  }

  .service__icon {
    height: 2.7rem;
    width: 2.7rem;
  }

  .service__icon > svg {
    height: 1.7rem;
    width: 1.7rem;
    fill: #fff;
  }

  .service h2 {
    font-size: 1.25rem;
  }

  .service p {
    line-height: 1.77;
    font-size: 1rem;
    text-align: center;
    padding: 0 1.5rem;
  }

  .service ul li {
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0.4rem 0;
  }

  .service ul {
    padding: 0;
    column-gap: 0rem;
  }

  .service__header {
    gap: 1rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .service {
    gap: 1rem;
    padding: 2rem;
  }
  .service h2 {
    font-size: 1.5rem;
  }

  .service ul li {
    font-size: 1.3rem;
  }
  .service p {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  .service__header {
    gap: 1.5rem;
  }
}
