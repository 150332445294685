.button {
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.button__primary {
  background-color: var(--color-septenary);
}

.button__primary:hover {
  background-color: var(--color-tertiary);
}

.button__secondary {
  background-color: var(--color-senary);
}

.button__secondary:hover {
  background-color: var(--color-quinary);
}

.button__wide {
  width: 100%;
}

.float__right {
  float: right;
}

@media screen and (max-width: 639px) {
  .button {
    font-size: 0.8rem;
    padding: 0.3rem;
    width: 100%;
  }
}
