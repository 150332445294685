.modal {
  position: fixed;
  top: 10vh;
  left: 30%;
  width: 38%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 90;
}

@keyframes openModal {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes closeModal {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-3rem);
  }
}

.show {
  animation: openModal 0.3s ease-out forwards;
}

.hide {
  animation: closeModal 0.3s ease-out forwards;
}

.btn__close {
  float: right;
  background-color: inherit;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.content {
  height: 100%;
}

@media screen and (max-width: 639px) {
  .modal {
    top: 6vh;
    width: 95%;
    left: 2.5%;
    padding: 0.7rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1007px) {
  .modal {
    top: 6vh;
    width: 90%;
    left: 5.2%;
  }
}
@media screen and (min-width: 1008px) and (max-width: 1439px) {
  .modal {
    top: 6vh;
    width: 45%;
    left: 28%;
  }
}
